<template>
    <div>
        <v-text-field
            v-model="searchLocal"
            append-icon="mdi-search"
            label="Search"
            single-line
            hide-details />
    </div>
</template>

<script>
export default {
    props: {
        search: {
            type: String,
            default: null,
        },
    },

    computed: {
        searchLocal: {
            get() {
                return this.search;
            },
            set(search) {
                this.$emit("update:search", search);
            },
        },
    },
};
</script>
